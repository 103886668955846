import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Tooltip,
  Paper,
  Button,
  TextField,
  MenuItem,
  CardHeader,
  Typography,
  Stack,
  IconButton,
  Checkbox,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import {
  updateOfflineUsers,
  deleteOfflineUsers,
  getStateListAPI,
  getDetailList,
  restoreOfflineUser,
  shadiDoneMove,
} from "src/service";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuthContext } from "src/auth/useAuthContext";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import Image from "../../../../components/image";
import constant from "src/Constant";
import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import StaffToPayNewUser from "src/pages/UserFormData/StaffToPayNewUser";
// ----------------------------------------------------------------------

const ratingArray = [
  { key: 4, title: "A=80-100%" },
  { key: 3, title: "B=40-80%" },
  { key: 2, title: "C=1-40%" },
  { key: 1, title: "D=0%" },
];

export default function OfflineUsersCard({
  selected,
  selectAllChecked,
  selectedId,
  handleToggleAll,
  bioData,
  setUpdate,
  setShowUpdate,
  filterDetail,
  setAdd,
  update,
  goToTop,
  staffNumber,
  getOnlineUserListFunc,
  getOffineUsers,
  show,
  handleToggleOne,
  check,
}) {
  const contxt = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [costModal, setCostModal] = useState(false);
  const [mobileModel, setMobileModel] = useState(false);
  const [noteModalValue, setNoteModalValue] = useState("");
  const [costValue, setCostValue] = useState("");
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(bioData);
  const [open, setOpen] = useState(false);
  const [chnageRating, setChangeRating] = useState(false);
  const [ratingID, setRatingID] = useState(null);
  const [mobileValue, setMobileValue] = useState(newRow?.mobile || "");
  const [item, setItem] = useState({ item: update });
  const [note, setNote] = useState([]);
  const [cost, setCost] = useState([]);
  const [date, setDate] = useState([]);
  const [detailBoxOpen, setDetailBoxOpen] = useState(false);
  useEffect(() => {
    setItem({ item: update });
    if (update?.notes) setNote(update?.notes);
    if (update?.final_cost) setCost(update?.final_cost);
  }, [update]);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const parseDownloadHistory = (jsonString) => {
    if (!jsonString || typeof jsonString !== 'string') {
      return [];
    }
    const objects = jsonString.match(/{[^}]+}/g) || [];
    return objects.map(obj => JSON.parse(obj));
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const rawData = `{\"download_date\":\"2024-09-16T11:38:42.121Z\",\"download_reason\":\"default_reason\",\"download_history_id\":174,\"download_history_name\":\"rahul\"}{\"download_date\":\"2024-09-16T11:43:21.776Z\",\"download_reason\":\"default_reason\",\"download_history_id\":177,\"download_history_name\":\"rahul\"}`;

  // Add commas between the JSON objects and parse
  const parsedData = rawData.split('}{').map((item, index, arr) => {
    if (index !== arr.length - 1) {
      item = item + "}";
    }
    if (index !== 0) {
      item = "{" + item;
    }
    return JSON.parse(item);
  });

  const handleCopyMobile = (item) => {
    copy(item);
    enqueueSnackbar("Copied!");
  };
  const handleCopyName = () => {
    copy(newRow.name);
    enqueueSnackbar("Copied!");
  };

  const styles = {
    smallNormalText: {
      marginTop: "0px",
      fontWeight: "normal",
      color: "rgb(105, 105, 105)",
      fontSize: 12.5,
      whiteSpace: "nowrap",
    },
  };

  const handleClose = () => {
    setOpen(!open);
    getOffineUsers();
  };




  const downloadHistory = parseDownloadHistory(newRow?.download_historys_excel);

  const handleProfileDone = async () => {
    let obj = {
      verify_process: "Done",
      is_online_user: 1,
    };
    const res = await updateOfflineUsers(newRow?.id, obj);
    if (res?.data?.status) {
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    }
  };

  // const ratingArray = [
  //     { key: 0, title: "0" },
  //     { key: 1, title: "1" },
  //     { key: 2, title: "2" },
  //     { key: 3, title: "3" },
  //     { key: 4, title: "4" },
  //     { key: 5, title: "5" },
  //     // { key: 6, title: "W " },
  //     { key: 7, title: "S" },
  //     { key: 8, title: "L" },

  // ]

  const onStatus = async (obj) => {
    obj.id = newRow?.id;
    let res = await updateOfflineUsers(obj.id, obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    setNewRow({ ...newRow, ...obj });
  };

  const restoreUser = async (obj) => {
    obj.id = newRow?.id;
    let res = await restoreOfflineUser(obj.id);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    setNewRow({ ...newRow, ...obj });
  };

  const onDelete = async () => {
    let obj = {
      id: bioData?.id,
    };
    try {
      let res = await deleteOfflineUsers(obj, bioData?.id);

      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
        getOffineUsers();
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      enqueueSnackbar("An error occurred while deleting the user.", {
        variant: "error",
      });
    }
  };
  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${maskNumber(mobile)}`;
  };
  function callNow(mobile) {
    window.location.href = `tel:${maskNumber(mobile)}`;
  }

  useEffect(() => {
    currentDate();
  }, []);

  const currentDate = () => {
    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    setDate(today);
  };

  return (
    <div
      className={`${newRow?.OnlineUserShadiAndDeletedOff
        ? "offlineCard-Isonline"
        : "offlineCard"
        } m-2`}
    >
      {
        newRow?.OnlineUserShadiAndDeletedOff &&
          newRow?.OnlineUserShadiAndDeletedOff?.deleted_at === null &&
          newRow?.OnlineUserShadiAndDeletedOff?.shadi_done === null ? (
          <div className="goldenBadge">
            <Iconify icon="entypo:shield" />
            On App
          </div>
        ) : newRow?.OnlineUserShadiAndDeletedOff?.deleted_at &&
          newRow?.OnlineUserShadiAndDeletedOff?.shadi_done === null ? (
          <div className="goldenBadge" style={{ color: "#FF3030" }}>
            <Iconify
              icon="material-symbols:close-small"
              style={{
                background: "#FF3030",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Deleted
          </div>
        ) : newRow?.OnlineUserShadiAndDeletedOff?.shadi_done ? (
          <div className="goldenBadge" style={{ color: "#32BE8F" }}>
            <Iconify
              icon="material-symbols:check-small"
              style={{
                background: "#32BE8F",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Shadi Done
          </div>
        ) : null // Add this to handle other cases
      }
      <div
        className="d-flex justify-content-between primaryBg light"
        style={{
          padding: "8px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div className={`${newRow?.is_online_user ? "ms-4" : ""}`}>
          <div>
            {check && (
              <Checkbox
                size="small"
                checked={selected}
                onChange={() => handleToggleOne(newRow.id)}
              />
            )}
          </div>
          <div>
            {newRow.id && (
              <Typography
                className={`${newRow?.is_online_user ? "ms-4" : ""}`}
                variant="subtitle1"
              >
                {" "}
                #{newRow.id} &nbsp;
              </Typography>
            )}
            {newRow.name && (
              <Typography
                variant="subtitle1"
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  handleCopyName(newRow.name);
                }}
              >
                <AccountCircleIcon
                  className="fs-16"
                  style={{ marginRight: "5px" }}
                />
                {newRow.name} <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Typography>
            )}
          </div>
          {newRow?.type_of_data && (
            <span style={{ fontSize: "0.92rem", marginLeft: "20px" }}>
              {newRow?.type_of_data}
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleToggleAll(newRow.id)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: selectedId.includes(newRow.id) ? "#fff" : "#32be8f",
              border: "1px solid #fff",
              borderRadius: "5px",
              color: selectedId.includes(newRow.id) ? "#32be8f" : "#fff",
            }}
          >
            {selectedId.includes(newRow.id) ? "Unselect" : "Select"}{" "}
            {/* Change button text based on selection */}
          </Button>

          {contxt?.user?.sections?.offlineUsers?.update && (
            <EditNoteOutlinedIcon
              onClick={() => {
                setUpdate({ ...newRow });
                setMobileModel(true);
              }}
            />
          )}{" "}
        </div>

      </div>
      <div style={{ backgroundColor: '#008000', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 10px' }}>
        <span style={{ color: 'white', fontWeight: '300', textAlign: "center" }}>
          {newRow?.out_of_bharat_status === 1 ? 'OUT OF INDIA' : 'Indian user'}
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {newRow?.total_download_excel > 0 && (
            <>
              <span style={{ color: 'white', marginRight: '10px' }}>Download</span>
              <button
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'white',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setDetailBoxOpen(!detailBoxOpen)}
              >
                <span style={{ fontSize: '12px' }}>{newRow?.total_download_excel}</span>
              </button>
            </>
          )}
        </div>
      </div>



      {detailBoxOpen && (
        <div style={{
          position: 'absolute',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          zIndex: 1000
        }}>
          <div style={{ display: 'table', width: '100%', borderCollapse: 'collapse', border: '1px solid #ccc' }}>
            <div style={{ display: 'table-row', backgroundColor: '#f0f0f0' }}>
              <div style={{ display: 'table-cell', padding: '5px', fontWeight: 'bold', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>Date</div>
              <div style={{ display: 'table-cell', padding: '5px', fontWeight: 'bold', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>Purpose</div>
              <div style={{ display: 'table-cell', padding: '5px', fontWeight: 'bold', borderBottom: '1px solid #ccc' }}>Name</div>
            </div>
            {downloadHistory.map((item, index) => (
              <div key={index} style={{ display: 'table-row' }}>
                <div style={{ display: 'table-cell', padding: '5px', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                  {new Date(item.download_date).toLocaleString()}
                </div>
                <div style={{ display: 'table-cell', padding: '5px', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                  {item.download_reason}
                </div>
                <div style={{ display: 'table-cell', padding: '5px', borderBottom: '1px solid #ccc' }}>
                  {item.download_history_name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}


      <div className="p-2 px-3" style={{ background: "#fff" }}>
        {/* Header Section start*/}
        <div className="row pb-2">
          <div className="col-lg-4">
            {newRow?.createProfile || show === true ? (
              <span className="secondaryColor fw-500 fs-14 px-2">
                ES :{" "}
                <span style={{ color: "#000" }}>
                  {newRow?.eStaff?.name?.english}
                </span>
              </span>
            ) : (
              <span className="secondaryColor fw-500 fs-14 px-2">
                ES :{" "}
                <span style={{ color: "#000" }}>
                  {newRow?.entryBy?.name?.english}
                </span>
              </span>
            )}
            <hr className="my-1" />
            <span className="fs-12">{`${moment(newRow?.created_at).format(
              "DD-MM-YY, h:mm"
            )}`}</span>
          </div>
          {newRow?.whatsapp_status && (
            <div className="col-lg-4">
              <span className="secondaryColor fw-500 fs-14">
                WS :{" "}
                <span style={{ color: "#000" }}>
                  {newRow?.wStaff?.name?.english}
                </span>
              </span>
              <hr className="my-1" />
              <span className="fs-12">{`${moment(
                newRow?.whatsapp_date_time
              ).format("DD-MM-YY, h:mm")}`}</span>
            </div>
          )}
          {newRow?.call_status && (
            <div className="col-lg-4">
              {newRow?.createProfile || show === true ? (
                <span className="secondaryColor fw-500 fs-14 px-2">
                  CS :{" "}
                  <span style={{ color: "#000" }}>
                    {newRow?.cStaff?.name?.english}
                  </span>
                </span>
              ) : (
                <span className="secondaryColor fw-500 fs-14 px-2">
                  CS :
                  <span style={{ color: "#000" }}>
                    {newRow?.call_By?.name?.english}
                  </span>
                </span>
              )}
              <hr className="my-1" />
              <span className="fs-12">{`${moment(newRow?.call_date_time).format(
                "DD-MM-YY, h:mm"
              )}`}</span>
            </div>
          )}
        </div>
        {/* Header Section end */}

        {/* Second section start */}
        <div className="displayBtwPro">
          <div>
            {newRow?.mobile?.split(",").map((item) => (
              <Button
                className="roundBtn"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleCopyMobile(maskNumber(item));
                }}
              >
                {maskNumber(item)}
                <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Button>
            ))}
          </div>
          <div>
            <button
              className="WtsBtn"
              style={{
                borderColor: "blue",
                color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                background: newRow?.whatsapp_status ? "#525FFE" : "transparent",
              }}
              onClick={() => {
                onStatus({
                  whatsapp_status: !newRow?.whatsapp_status,
                  whatsapp_date_time: date,
                  whatsapp_action_id: contxt?.user?.id,
                });
              }}
            >
              WA
            </button>

            <button
              className="WtsBtn"
              style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
              onClick={() => {
                whatsappNow(newRow?.mobile);
              }}
            >
              <WhatsAppIcon />
            </button>
            <button
              className="WtsBtn"
              style={{ borderColor: "blue", color: "blue" }}
              onClick={() => {
                callNow(newRow?.mobile);
              }}
            >
              <CallIcon />
            </button>
          </div>
        </div>
        {/* Second Section end */}

        {/* Third section start */}
        <div className="displayBtwPro">
          <Box display="flex" alignItems="center">
            <Switch
              checked={newRow?.call_status ? true : false}
              onChange={() => {
                onStatus({
                  call_status: newRow?.call_status ? false : true,
                  call_date_time: date,
                  calling_action_id: contxt?.user?.id,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              Call
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Switch
              checked={newRow?.pickup_status ? true : false}
              onChange={() => {
                onStatus({
                  pickup_status: newRow?.pickup_status ? false : true,
                  pickup_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NP
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Switch
              checked={newRow?.not_connected_status ? true : false}
              onChange={() => {
                onStatus({
                  not_connected_status: newRow?.not_connected_status
                    ? false
                    : true,
                  not_connected_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NC
            </Typography>
          </Box>
        </div>
        {/* Third section end  */}

        {/* Fourth Section start */}
        {newRow?.call_status && (
          <div>
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "10px" }}
            >
              <Button
                size="small"
                variant={newRow?.sambandh_click ? "contained" : "outlined"}
                className="btnPadding"
              >
                Sambandh
              </Button>
              <Button
                size="small"
                variant={newRow?.share_status ? "contained" : "outlined"}
                className="btnPadding"
              >
                Share
              </Button>
              <Button
                size="small"
                variant={newRow?.later_status ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    later_status: !newRow?.later_status,
                    later_date_time: new Date(),
                  })
                }}
              >
                Later
              </Button>
              <Button
                size="small"
                variant={newRow?.special ? "contained" : "outlined"}
                className="btnPadding"
              >
                Special
              </Button>
              <Button
                size="small"
                variant={
                  newRow?.not_interested_status ? "contained" : "outlined"
                }
                className="btnPadding"
              >
                Not Interested
              </Button>
              <Button
                size="small"
                variant={newRow?.not_jain ? "contained" : "outlined"}
                className="btnPadding"
              >
                Hindu
              </Button>
              <Button
                size="small"
                variant={newRow?.other_cast ? "contained" : "outlined"}
                className="btnPadding"
              >
                Other Cast
              </Button>
              <Button
                size="small"
                variant={newRow?.shadi_done_status ? "contained" : "outlined"}
                className="btnPadding"
                onClick={async () => {
                  // Update status with onStatus
                  onStatus({
                    shadi_done_status: !newRow?.shadi_done_status,
                    shadi_done_date_time: new Date(),
                  });
                  try {
                    const response = await shadiDoneMove(newRow?.id);
                    if (response?.status === 200) {
                      console.log('API call successful:', response.data);
                    } else {
                      console.error('API call failed with status:', response?.status);
                    }
                  } catch (error) {
                    console.error('Error in API call:', error);
                  }
                }}
              >
                Shadi Done
              </Button>
              <Button
                size="small"
                variant={
                  newRow?.online_by_other_mobile_avai ? "contained" : "outlined"
                }
                className="btnPadding"
              >
                On App
              </Button>
              <Button
                size="small"
                variant={newRow?.different_language ? "contained" : "outlined"}
                className="btnPadding"
              >
                Diff Language
              </Button>
            </div>
            {/* rating section start */}
            <div className="my-2">
              {ratingArray?.map((i) => (
                <Button
                  className="ratingSec"
                  key={i?.key}
                  variant={newRow?.rating === i?.key ? "contained" : "outlined"}
                  size="small"
                >
                  {i?.title}
                </Button>
              ))}
            </div>
            {/* rating section end */}

            {/* Sambandh section preview */}
            {newRow?.sambandh?.length > 0 && (
              <>
                {" "}
                {newRow?.sambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "0" && (
                        <div className="row align-items-center">
                          <div className="col-10 pe-0">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>SF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? "- " + val?.name : ""}
                              {val?.mobile
                                ? "- " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name.trim()
                                ? "- " + val?.relative_name
                                : ""}
                              {val?.age ? "- " + val?.age : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {newRow?.sambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "1" && (
                        <div className="row align-items-center">
                          <div className="col-10">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>OF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? ": " + val?.name : ""}
                              {val?.mobile
                                ? ": " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name
                                ? ": " + val?.relative_name
                                : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
            <div className="row ">
              <div className="col-12">
                {newRow?.partner_preference ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Partner Preference : </b>
                    {newRow?.partner_preference}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.different_language ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Different Language : </b>
                    {newRow?.different_language} |{" "}
                    {newRow?.different_language_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.not_interested_notes ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Not Interested Reason : </b>
                    {newRow?.not_interested_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* notes show */}
            <div className="row">
              {newRow?.other && (
                <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                  {newRow?.other
                    ? "Notes : " + newRow?.other + newRow?.notes
                    : "" + <br />}
                  <span className="fs-12 fw-500" style={{ color: "red" }}>
                    {" "}
                    [
                    {newRow?.notes_date_time
                      ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") +
                      " , " +
                      moment(newRow?.notes_date_time)?.format("HH:mm")
                      : ""}
                    ]
                  </span>
                </div>
              )}
            </div>

            {/* notes show */}
            <div className="row ">
              {(newRow?.special || newRow?.special_notes) && (
                <div className="col-12 fs-14">
                  <span style={{ color: "#525ffe" }}>
                    {" "}
                    {newRow?.special
                      ? "Special Share : " + newRow?.special
                      : ""}{" "}
                    |{" "}
                  </span>
                  <span style={{ color: "#EB445A" }}>
                    {newRow?.special_notes ? newRow?.special_notes : ""}
                  </span>
                </div>
              )}
            </div>
            {/* notes show */}
            <div className="row">
              {newRow?.normal_share && (
                <div className="col-12 fs-14" style={{ color: "#EB445A" }}>
                  {newRow?.normal_share
                    ? "Share : " + newRow?.normal_share
                    : "" + <br />}
                </div>
              )}
            </div>
            <div className="row">
              {newRow?.normal_share && (
                <div className="col-12 fs-14" style={{ color: "#EB445A" }}>
                  {newRow?.normal_share
                    ? "Share Card Note : " + newRow?.share_staff_comment
                    : "" + <br />}
                </div>
              )}
            </div>

            {/* followup section */}
            {newRow?.later_status && (
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-14">
                  <span style={{ color: "red" }}>Follow up :</span>{" "}
                  {newRow?.follow_date ? (
                    <span style={{ fontSize: "13px" }}>
                      Date : {newRow?.follow_date}
                    </span>
                  ) : (
                    <span>Date : --- </span>
                  )}
                </div>
                <div className="fs-14">
                  {newRow?.follow_time ? (
                    <span style={{ fontSize: "13px" }}>
                      Time : {newRow?.follow_time}
                    </span>
                  ) : (
                    <span>Time : ---</span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <hr />
          {/* Share to section */}
          <div className="ShareSec">
            {newRow?.share_section ? (
              <p className="m-0">{newRow?.share_section}</p>
            ) : (
              <span>Share to</span>
            )}
          </div>

          {/* Add Pay/Bio/Pic section */}
          <div className="displayBtwPro">
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.biodataImage || newRow?.image ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Bio/Pic
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.online_user_payment_screenshot ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Pay
              </Typography>
            </Box>
          </div>
          <div className="row">
            <div className="col-6">
              {newRow?.biodataImage || newRow?.image ? (
                <div className="row">
                  <div className="col-12">
                    {newRow?.biodataImage ? (
                      <Image
                        alt={newRow.value}
                        src={`${constant.appBaseUrl}/users/offline_users/${newRow?.biodataImage}`}
                        sx={{ borderRadius: 1 }}
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    ) : (
                      <Image
                        src={blankUSer}
                        alt="about-vision"
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-12">
                    <div>
                      {newRow?.image ? (
                        <Image
                          alt={newRow.value}
                          src={`${constant.appBaseUrl}/users/offline_users/${newRow?.image}`}
                          sx={{ borderRadius: 1 }}
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                        />
                      ) : (
                        <Image
                          src={blankUSer}
                          alt="about-vision"
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6">
              {newRow?.online_user_payment_screenshot ? (
                <div>
                  <div className="ShareSec">
                    {" "}
                    {newRow?.online_user_package ? (
                      <p className="m-0">{newRow?.online_user_package}</p>
                    ) : (
                      <span>Select...</span>
                    )}{" "}
                  </div>
                  <p className="my-2" style={{ borderBottom: "1px solid" }}>
                    {" "}
                    {newRow?.online_user_done_amount ? (
                      <p className="m-0">{newRow?.online_user_done_amount}</p>
                    ) : (
                      ""
                    )}
                  </p>
                  {newRow?.online_user_payment_screenshot ? (
                    <Image
                      alt={newRow.value}
                      src={`${constant.appBaseUrl}/users/payment_screen_shot/${newRow?.online_user_payment_screenshot}`}
                      sx={{ borderRadius: 1 }}
                      style={{
                        width: "120px",
                        height: 100,
                        borderRadius: 8,
                        objectFit: "fill",
                      }}
                    />
                  ) : (
                    <Image
                      src={blankUSer}
                      alt="about-vision"
                      style={{
                        width: "120px",
                        height: 100,
                        borderRadius: 8,
                        objectFit: "fill",
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* offline Note */}
          {newRow.OfflineUserNote &&
            newRow?.OfflineUserNote.map((i) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    Offline :- {i?.other}
                    <span
                      className="fs-14"
                      style={{ color: "#884A39", fontWeight: "500" }}
                    >
                      {" "}
                      {i?.notes}{" "}
                    </span>
                    {i?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{i?.NoteByStaffOffline?.name?.english}
                        {moment(i?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(i?.notes_date_time).format("DD/MM/YY")}
                        {i?.NoteByStaffOffline?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {/* Whatsapp Response */}
          {newRow?.OffWhatsRes?.OffWhatsReserHist?.length > 0 &&
            newRow?.OffWhatsRes?.OffWhatsReserHist?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Whatsapp Response :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.OffWhatsRes ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.OffWhatsRes?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffWhatsRes?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffWhatsRes?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffWhatsRes?.OffCallWstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffWhatsRes?.pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffWhatsRes?.pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffWhatsRes?.OffCallWstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.OffWhatsRes?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.OffWhatsRes?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffWhatsRes?.OffCallWstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.normal_share && (
                <span style={{ color: "red" }}>
                  Whatsapp Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.normal_share}
                    {newRow?.OffWhatsRes?.share_notes}
                  </span>{" "}
                  &nbsp;
                </span>
              )}
              {newRow?.OffWhatsRes?.special_notes && (
                <span style={{ color: "red" }}>
                  Whatsapp Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.OffWhatsRes?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.OffWhatsRes?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.OffWhatsRes?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* whatsapp end */}
          {/* group services */}
          {newRow?.OffGroupS?.OffGroupSer?.length > 0 &&
            newRow?.OffGroupS?.OffGroupSer?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Group Service :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {/* share card notes  */}
          <p className="m-0">
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "red",
              }}
            >
              Share notes :{" "}
            </span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
              }}
            >
              {newRow?.STPNOTEINOLLINE?.share_staff_comment}

              <div>
                <span>
                  [Time:{" "}
                  {newRow?.STPNOTEINOLLINE?.share_staff_comment_date_time
                    ? moment(newRow?.share_staff_comment_date_time).format(
                      "DD-MM-YY, h:mm a"
                    )
                    : "No Date and Time"}
                  ] [Name:{" "}
                  {newRow?.STPNOTEINOLLINE?.ShareCardNoteByStaff?.name?.english}
                  ]
                </span>
              </div>
            </span>
          </p>
          {newRow?.OffGroupS ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.OffGroupS?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffGroupS?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffGroupS?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffGroupS?.OffCallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffGroupS?.pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffGroupS?.pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffGroupS?.pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffGroupS?.OffCallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffGroupS?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffGroupS?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffGroupS?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffGroupS?.OffCallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffGroupS?.normal_share && (
                <span style={{ color: "red" }}>
                  Group Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.OffGroupS?.normal_share}
                    {newRow?.OffGroupS?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.OffGroupS?.special_notes && (
                <span style={{ color: "red" }}>
                  Group Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.OffGroupS?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.OffGroupS?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Different Language : -
                  <span className="textDark">
                    {newRow?.OffGroupS?.different_language_notes}
                  </span>{" "}
                </p>
              )}
              {newRow?.OffGroupS?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.OffGroupS?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.OffGroupS?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.OffGroupS?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.OffGroupS?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.OffGroupS?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* group note end */}

          {/* Paid Promotion */}
          {newRow?.OffPaidPa?.OffPaidPhistory?.length > 0 &&
            newRow?.OffPaidPa?.OffPaidPhistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Paid Promotion :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.OffPaidPa ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.OffPaidPa?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffPaidPa?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffPaidPa?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffPaidPa?.OffCallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffPaidPa?.pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffPaidPa?.pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffPaidPa?.OffCallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffPaidPa?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffPaidPa?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffPaidPa?.OffCallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.normal_share && (
                <span style={{ color: "red" }}>
                  Paid Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.normal_share}
                    {newRow?.OffPaidPa?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.OffPaidPa?.special_notes && (
                <span style={{ color: "red" }}>
                  Paid Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.OffPaidPa?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.OffPaidPa?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.OffPaidPa?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* paid note end */}

          {/* Social Media */}
          {newRow?.OffSocialM?.OffSocialHistory?.length > 0 &&
            newRow?.OffSocialM?.OffSocialHistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Social Media :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.OffSstaffName?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.OffSstaffName?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.OffSocialM ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.OffSocialM?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffSocialM?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffSocialM?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffSocialM?.OffCallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffSocialM?.pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffSocialM?.pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffSocialM?.OffCallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.OffSocialM?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.OffSocialM?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.OffSocialM?.OffCallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.normal_share && (
                <span style={{ color: "red" }}>
                  Social Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.normal_share}
                    {newRow?.OffSocialM?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.OffSocialM?.special_notes && (
                <span style={{ color: "red" }}>
                  Social Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.OffSocialM?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.OffSocialM?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.OffSocialM?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* social note end */}
          {/* Note Section */}
          <div>
            <Box
              display="flex"
              sx={{
                background: "#f2f2fe",
                color: "blue",
                padding: "5px",
                borderRadius: "8px",
              }}
            >
              <Typography variant="subtitle2"> Note : &nbsp;</Typography>
              {newRow.notes && (
                <Typography variant="subtitle2">{newRow.notes}</Typography>
              )}
            </Box>
            <Box display="flex" sx={{ padding: "5px" }}>
              <Typography variant="overline"> Cost : &nbsp;</Typography>
              {newRow.final_cost && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "normal",
                    color: "rgb(105, 105, 105)",
                    fontSize: 12.5,
                    color: "text.secondary",
                  }}
                >
                  {newRow.final_cost}
                </Typography>
              )}
            </Box>
            <Typography variant="subtitle2" color="red">
              {" "}
              Moved By: &nbsp;
              <span style={{ color: "grey", fontWeight: "400" }}>
                Vandana-tech &nbsp;
              </span>
              <span style={{ color: "blue", fontWeight: "400" }}>
                [18/02/2024, 10:10]
              </span>
            </Typography>
            <Typography variant="subtitle2" color="red">
              {" "}
              Restore By: &nbsp;
              <span style={{ color: "grey", fontWeight: "400" }}>
                Vandana-tech &nbsp;
              </span>
              <span style={{ color: "blue", fontWeight: "400" }}>
                [18/02/2024, 10:10]
              </span>
            </Typography>
            <Box></Box>
          </div>
        </div>
        {/* Fourth Section end */}

        {/* Last Button Section start */}
        <Grid container sx={{ textAlign: "center", padding: "10px 0px" }}>
          {contxt?.user?.sections?.offlineUsers?.delete ? (
            <Grid item lg={3} md={3} sm={6} xs={6} mb={{ sm: 1, xs: 1 }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleOpenConfirm();
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {contxt?.user?.sections?.offlineUsers?.update ? (
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={() => {
                  setUpdate({ ...newRow });
                  setShowUpdate(false);
                  goToTop();
                }}
              >
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          {contxt?.user?.sections?.offlineUsers?.update ? (
            <>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setNoteModal(true);
                    setUpdate({ ...newRow });
                  }}
                >
                  Notes
                </Button>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <Button variant="contained" size="small" onClick={restoreUser}>
                  Restore
                </Button>
                {/* <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCostModal(true);
                    setUpdate({ ...newRow });
                  }}
                >
                  Cost
                </Button> */}
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        {/* Last Button Section end  */}

        {/* Create Profile */}
        {newRow?.createProfile && (
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={() => setOpen(!open)}
                >
                  Create Profile
                </Button>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={handleProfileDone}
                >
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={mobileModel}
        onClose={() => {
          setMobileModel(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setMobileModel(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Edit Mobile"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Mobile"
                type="number"
                placeholder="Type  here.."
                value={maskNumber(mobileValue)}
                onChange={(e) => {
                  setMobileValue(e.target.value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({
                      mobile: mobileValue,
                      mobiles: {
                        mobile: mobileValue,
                        is_whatsapp: update?.mobiles?.is_whatsapp,
                        is_call_done: update?.mobiles?.is_call_done,
                        time: new Date(),
                      },
                    });
                    setNewRow({ ...newRow, mobile: mobileValue });
                    setMobileModel(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      {/* add notes */}
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={noteModal}
        onClose={() => {
          setNoteModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setNoteModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Notes"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Note"
                placeholder="Type  here.."
                value={note}
                onChange={(e, value) => {
                  setNoteModalValue(e.target.value);
                  setNote(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({ notes: noteModalValue });
                    setNewRow({ ...newRow, notes: noteModalValue });
                    setNoteModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />

      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={costModal}
        onClose={() => {
          setCostModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setCostModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Cost"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Cost"
                placeholder="Type  here.."
                value={cost}
                onChange={(e, value) => {
                  setCostValue(e.target.value);
                  setCost(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({ final_cost: costValue });
                    setNewRow({ ...newRow, final_cost: costValue });
                    setCostModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      <StaffToPayNewUser
        open={open}
        cardType={"offline"}
        getOnlineUserListFunc={getOnlineUserListFunc}
        handleClose={handleClose}
        filterDetail={filterDetail}
      />
    </div>
  );
}
