// ----------------------------------------------------------------------

function path(sublink) {
  return `${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: path("/login"),
  register: path("/register"),
  loginUnprotected: path("/login-unprotected"),
  registerUnprotected: path("/register-unprotected"),
  verify: path("/verify"),
  resetPassword: path("/reset-password"),
  newPassword: path("/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  kanban: path("/kanban"),
  calendar: path("/calendar"),
  fileManager: path("/files-manager"),
  permissionDenied: path("/permission-denied"),
  blank: path("/blank"),
  general: {
    app: path("/app"),
    onlineuser: path("/onlineuser"),
    hinduonlineuser: path("/hinduonlineuser"),
    pendingTask: path("/pendingTask"),
    downloadHistory: path("/download-history"),
    analyticsHistory: path("/analytics-history"),
    newGlobalSearch: path("/newGlobalSearch"),
    shareTask: path("/shareTask"),
    deleteduser: path("/deleteduser"),
    hindudeleteduser: path("/hindudeleteduser"),
    bannerpage: path("/bannerpage"),
    testUserHistory: path("/testUserHistory"),
    mypackages: path("/mypackages"),
    biodatausers: path("/biodatausers"),
    bioDataEntry: path("/biodataentryforms"),
    hinducasteentries: path("/hinducasteentries"),
    pushNotification: path("/pushnotification"),
    pushNotificationHistory: path("/pushnotificationhistory"),
    hinduPushNotificationHistory: path("/hindupushnotificationhistory"),
    mystaff: path("/mystaff"),
    mobiles: path("/mobiles"),
    contactus: path("/contactus"),
    scannerUpload: path("/scannerUpload"),
    scannerUploadHindu: path("/scannerUploadHindu"),
    hindubiodata: path("/hindubiodata"),
    hinduincoming: path("/hinduincoming"),
    incominguser: path("/incominguser"),
    incominguseradd: path("/incominguseradd"),
    hinduincomingadd: path("/hinduincomingadd"),
    offlineUsers: path("/offlineUsers"),
    offlineUsersadd: path("/offlineUsersadd"),
    hindubioDataEntry: path("/hindubiodataentryforms"),
    addMessageSection: path("/addMessageSection"),
    languageViewSection: path("/languageViewSection"),
    officeaccessoriesHistory: path("/officeaccessoriesHistory/:id"),
    forms: path("/forms"),
    countries: path("/countries"),
    userRequest: path("/userRequest"),
    states: path("/states"),
    cities: path("/cities"),
    tehsils: path("/tehsils"),
    educations: path("/educations"),
    subeducations: path("/subeducations"),
    subsects: path("/subsects"),
    sects: path("/sects"),
    gotras: path("/gotras"),
    hobbies: path("/hobbies"),
    mothertongues: path("/mothertongues"),
    occupations: path("/occupations"),
    suboccupations: path("/suboccupations"),
    specialcollegespreferences: path("/specialcollegespreferences"),
    padadhikari: path("/padadhikari"),
    officeaccessories: path("/officeaccessories"),
    staffreport: path("/staffreport"),
    biodatauploading: path("/biodatauploading"),
    hindubiodatauploading: path("/hindubiodatauploading"),
    biodatadawnloading: path("/biodatadawnloading"),
    biodatadawnloadingupdate: path("/biodatadawnloadingupdate"),
    grouplinks: path("/grouplinks"),
    responsedata: path("/responsedata"),
    hinduresponsedata: path("/hinduresponsedata"),
    whatsappresponse: path("/whatsappresponse"),
    whatsappgroupservices: path("/whatsappgroupservices"),
    paidpromotion: path("/paidpromotion"),
    socialmedia: path("/socialmedia"),
    hinduwhatsappresponse: path("/hinduwhatsappresponse"),
    whatsappresponseadd: path("/whatsappresponseadd"),
    responseimportdata: path("/responseimportdata"),
    hinduwhatsappresponseadd: path("/hinduwhatsappresponseadd"),
    rolemanagement: path("/rolemanagement"),
    fieldapprovel: path("/fieldapprovel"),
    analytics: path("/analytics"),
    raashi: path("/raashi"),
    starnakshatra: path("/starnakshatra"),
    message: path("/message"),
    notes: path("/notes"),
    fieldwork: path("/fieldwork"),
    fieldworkadd: path("/fieldworkadd"),
    hindubiodatadawnloading: path("/hindubiodatadawnloading"),
    hindubiodatadawnloadingupdate: path("/hindubiodatadawnloadingupdate"),
    hinduforms: path("/hinduforms"),
    hinducountries: path("/hinducountries"),
    hindustates: path("/hindustates"),
    hinducities: path("/hinducities"),
    hindutehsils: path("/hindutehsils"),
    hindueducations: path("/hindueducations"),
    hindusubeducations: path("/hindusubeducations"),
    hindusects: path("/hindusects"),
    hindusubsects: path("/hindusubsects"),
    hindugotras: path("/hindugotras"),
    hinduoccupations: path("/hinduoccupations"),
    hindusuboccupations: path("/hindusuboccupations"),
    globalsearch: path("/globalsearch"),
    excelhistory: path("excelhistory"),
    settings: path("settings"),
  },
  mail: {
    root: path("/mail"),
    all: path("/mail/all"),
  },
  chat: {
    root: path("/chat"),
    new: path("/chat/new"),
    view: (name) => path(`/chat/${name}`),
  },
  user: {
    root: path("/user"),
    new: path("/user/new"),
    list: path("/user/list"),
    cards: path("/user/cards"),
    profile: path("/user/profile"),
    account: path("/user/account"),
    edit: (id) => path(`/ user / ${id} /edit`),
    // edit: (name) => path( `/user/${name}/edit`),
    demoEdit: path(`/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path("/e-commerce"),
    shop: path("/e-commerce/shop"),
    list: path("/e-commerce/list"),
    checkout: path("/e-commerce/checkout"),
    new: path("/e-commerce/product/new"),
    view: (name) => path(`/e-commerce/product/${name}`),
    edit: (name) => path(`/e-commerce/product/${name}/edit`),
    demoEdit: path("/e-commerce/product/nike-blazer-low-77-vintage/edit"),
    demoView: path("/e-commerce/product/nike-air-force-1-ndestrukt"),
  },
  invoice: {
    root: path("/invoice"),
    list: path("/invoice/list"),
    new: path("/invoice/new"),
    view: (id) => path(`/invoice/${id}`),
    edit: (id) => path(`/invoice/${id}/edit`),
    demoEdit: path("/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"),
    demoView: path("/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"),
  },
  blog: {
    root: path("/blog"),
    posts: path("/blog/posts"),
    new: path("/blog/new"),
    view: (title) => path(`/blog/post/${title}`),
    demoView: path(
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
};

export const PATH_DOCS = {
  root: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
};

export const PATH_ZONE_ON_STORE =
  "https://mui.com/store/items/zone-landing-page/";

export const PATH_MINIMAL_ON_STORE =
  "https://mui.com/store/items/minimal-dashboard/";

export const PATH_FREE_VERSION =
  "https://mui.com/store/items/minimal-dashboard-free/";

export const PATH_FIGMA_PREVIEW =
  "https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1";
