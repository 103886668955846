import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DropdownDatePicker = ({
  onChange,
  defaultDate = null,
  isTimer = false,
  hasYear = false,
  hasHour = false,
}) => {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [time, setTime] = useState(null);

  const parseISODate = (isoString) => {
    const dateObj = new Date(isoString);
    return {
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      date: dateObj.getDate(),
      time: isTimer ? dateObj : null,
    };
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDate(newDate);
    updateDate(newDate, month, year);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
    updateDate(date, month, year, newTime);
  };

  useEffect(() => {
    if (defaultDate) {
      // Ensure defaultDate is a string
      const defaultDateString =
        typeof defaultDate === "string"
          ? defaultDate
          : defaultDate.toISOString();

      // Split the date and time from the ISO string
      const [defaultDatePart, defaultTimePart] = defaultDateString.split("T");

      const [defaultYear, defaultMonth, defaultDateValue] =
        defaultDatePart.split("-");

      setYear(defaultYear);
      setMonth(Number(defaultMonth));
      setDate(Number(defaultDateValue));

      // Set the time part if available
      if (defaultTimePart) {
        const [hours, minutes] = defaultTimePart.split(":");
        setTime(new Date(0, 0, 0, hours, minutes));
      }
    }
  }, [defaultDate]);

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    updateDate(date, newMonth, year);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setYear(newYear);
    updateDate(date, month, newYear);
  };

  const updateDate = (date, month, year, selectedTime = time) => {
    const formattedDate = [
      String(year).padStart(4, "0"),
      String(month).padStart(2, "0"),
      String(date).padStart(2, "0"),
    ].join("-");

    // Optionally append time if `isTimer` is true and time is selected
    const formattedDateTime =
      isTimer && selectedTime
        ? `${formattedDate} ${selectedTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`
        : formattedDate;

    onChange(formattedDateTime);
  };

  const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => i + start).map(
      (value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )
    );
  };

  return (
    // <Grid container spacing={2}>
    //     <Grid item xs={3}>
    //         <FormControl fullWidth>
    //             <InputLabel>Year</InputLabel>
    //             <Select value={year} onChange={handleYearChange} label="Year">
    //                 {generateOptions(1900, 2100)}
    //             </Select>
    //         </FormControl>
    //     </Grid>
    //     <Grid item xs={3}>
    //         <FormControl fullWidth>
    //             <InputLabel>Month</InputLabel>
    //             <Select value={month} onChange={handleMonthChange} label="Month">
    //                 {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
    //                     <MenuItem key={index} value={index + 1}>
    //                         {month}
    //                     </MenuItem>
    //                 ))}
    //             </Select>
    //         </FormControl>
    //     </Grid>
    //     <Grid item xs={3}>
    //         <FormControl fullWidth>
    //             <InputLabel>Day</InputLabel>
    //             <Select value={date} onChange={handleDateChange} label="Date">
    //                 {generateOptions(1, 31)}
    //             </Select>
    //         </FormControl>
    //     </Grid>
    // </Grid>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        {/* <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select value={year} onChange={handleYearChange} label="Year">
              {generateOptions(1900, 2100)}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Month</InputLabel>
            <Select value={month} onChange={handleMonthChange} label="Month">
              {[
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
              ].map((month, index) => (
                <MenuItem key={index} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Day</InputLabel>
            <Select value={date} onChange={handleDateChange} label="Date">
              {generateOptions(1, 31)}
            </Select>
          </FormControl>
        </Grid>
        {hasYear && (
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={handleYearChange} label="Year">
                {generateOptions(1900, 2100)}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isTimer && (
          <Grid item xs={3}>
            <TimePicker
              label="Hours"
              value={time || null}
              onChange={handleTimeChange}
              views={["hours"]}
              ampm={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "HH",
                    maxLength: 2,
                  }}
                />
              )}
            />
          </Grid>
        )}

        {hasHour && (
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Hours</InputLabel>
              <Select
                value={time ? time.getHours() % 12 || 12 : ''}
                onChange={(event) => {
                  const newHour = event.target.value
                  const newTime = new Date(time || new Date())
                  newTime.setHours(newHour === 12 ? 0 : newHour)
                  handleTimeChange(newTime)
                }}
                label="Hours"
              >
                {[...Array(12)].map((_, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {String(index + 1).padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

      </Grid>
    </LocalizationProvider>
  );
};

export default DropdownDatePicker;
