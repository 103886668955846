import { Navigate, useRoutes } from "react-router-dom";
import React from "react";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  Page404,
} from "./elements";
import NewGlobalSearch from "src/pages/General/NewGlobalSearch";
import DownlaodDataHistory from "src/pages/General/DownlaodDataHistory";
import OfficeAccessoriesHistory from "src/pages/General/OfficeAccessoriesHistory";
import AnalyticsHistory from "src/pages/General/AnalyticsHistory";
// import NewOnlineUser from "src/pages/General/NewOnlineUser";
// import BannerPage from "src/pages/General/BannerPage";
// import Mobiles from "src/pages/General/Mobiles";
// import Mystaff from "src/pages/General/Mystaff";
// import ViewOnlineUser from "src/pages/General/ViewOnlineUser";
// import MyPackages from "src/pages/General/MyPackages";
// import BioDataUsers from "src/pages/General/BioDataUsers";
// import BioDataEntryForms from "src/pages/General/BioDataEntryForms";
// import HinduCasteEntries from "src/pages/General/HinduCasteEntries";
// import HinduBioData from "src/pages/General/HinduBioData";
// import ImagePreview from "src/pages/General/ImagePreview";
// import HinduBioDataEntryForms from "src/pages/General/HinduBioDataEntryForms";
// // import OfflineUsers from "src/pages/General/OfflineUsers";
// import OfflineUsersAdd from "src/pages/General/OfflineUsersAdd";
// import Padadhikari from "src/pages/General/Padadhikari";
// import Countries from "src/pages/Forms/Countries";
// import States from "src/pages/Forms/States";
// import Cities from "src/pages/Forms/Cities";
// import Tehsils from "src/pages/Forms/Tehsils";
// import Educations from "src/pages/Forms/Educations";
// import Subeducations from "src/pages/Forms/Subeducations";
// import Subsects from "src/pages/Forms/Subsect";
// import Gotras from "src/pages/Forms/Gotras";
// import Hobbies from "src/pages/Forms/Hobbies";
// import MotherTongues from "src/pages/Forms/Mothertongues";
// import Occupations from "src/pages/Forms/Occupations";
// import Suboccupations from "src/pages/Forms/Suboccupations";
// import Specialcollegespreferences from "src/pages/Forms/Specialcollegespreferences";
// import Raashi from "src/pages/Forms/Raashi";
// import Starnakshatra from "src/pages/Forms/Starnakshatra";
// import Forms from "src/pages/General/Forms";
// import RoleManagement from "src/pages/General/RoleManagement";
// import StaffReport from "src/pages/General/StaffReport";
// import BioDataUploading from "src/pages/General/BiodataUploading";
// import HinduBioDataUploading from "src/pages/General/HinduBiodataUploading";
// import BiodataDawnloading from "src/pages/General/BiodataDawnloading";
// import BiodataDawnloadingUpdate from "src/pages/General/BiodataDawnloadingUpdate";
// import WhatsappResponse from "src/pages/General/WhatsappResponse";
// import HinduWhatsappResponse from "src/pages/General/HinduWhatsappResponse";
// import WhatsappResponseAdd from "src/pages/General/WhatsappResponseAdd";
// import HinduWhatsappResponseAdd from "src/pages/General/HinduWhatsappResponseAdd";
// import Message from "src/pages/General/Message";
// import Notes from "src/pages/General/Notes";
// import FieldWork from "src/pages/General/FieldWork";
// import FieldWorkAdd from "src/pages/General/FieldWorkAdd";
// import HinduBiodataDawnloading from "src/pages/General/HinduBiodataDawnloading";
// import HinduBiodataDawnloadingUpdate from "src/pages/General/HinduBiodataDawnloadingUpdate";
// import Hinduforms from "src/pages/General/HinduForms";
// import HinduCountries from "src/pages/Hinduforms/Countries";
// import HinduStates from "src/pages/Hinduforms/States";
// import HinduCities from "src/pages/Hinduforms/Cities";
// import HinduTehsils from "src/pages/Hinduforms/Tehsils";
// import HinduEducations from "src/pages/Hinduforms/Educations";
// import HinduSubeducations from "src/pages/Hinduforms/Subeducations";
// import HinduSubsects from "src/pages/Hinduforms/Subsects";
// import HinduSects from "src/pages/Hinduforms/Sects";
// import HinduGotras from "src/pages/Hinduforms/Gotras";
// import HinduOccupations from "src/pages/Hinduforms/Occupations";
// import HinduSuboccupations from "src/pages/Hinduforms/Suboccupations";
// import GlobalSearch from "src/pages/General/GlobalSearch";
// import ExcelHistory from "src/pages/General/ExcelHistory";
// import HinduBioDataUpdate from "src/pages/General/HinduBioDataUpdate";
// import BioDataUpdate from "src/pages/General/BioDataUpdate";
// import PushNotification from "src/pages/General/PushNotification";
// import PushNotificationAdd from "src/pages/General/PushNotificationAdd";
// import HinduIncoming from "src/pages/General/HinduIncoming";
// import HinduIncomingAdd from "src/pages/General/hinduIncomingAdd";
// import TestUserHistory from "src/pages/General/TestUserHistory";
// import UserRequest from "src/pages/General/UserRequest";
// import ContactUs from "src/pages/General/ContactUs";
// import PushNotificationHistory from "src/pages/General/PushNotificationHistory";
// import ResponseData from "src/pages/General/ResponseData";
// import WhatsappGroupServices from "src/pages/General/WhatsappGroupServices";
// import PaidPromotion from "src/pages/General/PaidPromotion";
// import SocialMedia from "src/pages/General/SocialMedia";
// import ExcelImport from "src/sections/@dashboard/user/list/ExcelImport";
// import PendingTask from "src/pages/General/PendingTask";
// import IncomingUser from "src/pages/General/IncomingUser";
// import IncomingUserAdd from "src/pages/General/IncomingUserAdd";
// import MessageSection from "src/pages/General/MessageSection";
// import GroupLinks from "src/pages/General/GroupLinks";
// import HinduOnlineUser from "src/pages/General/HinduOnlineUser";
// import Settings from "src/pages/General/Settings";
// import MessageView from "src/pages/General/MessageView";
// import FieldApprovel from "src/pages/General/FieldApprovel";
// import HinduResponseData from "src/pages/General/HinduResponseData";
// import HinduViewOnlineUser from "src/pages/General/HinduViewOnlineUser";
// import HinduImagePreview from "src/pages/General/HinduImagePreview";
// import HinduPushNotificationHistory from "src/pages/General/HinduPushNotificationHistory";
// import ViewStaff from "src/pages/General/ViewStaff";
// import BioDataScannerUpdate from "src/pages/General/BiodataScannerUpdate";
// import BioDataUpdateScannerById from "src/pages/General/BioDataUpdateScannerById";
// import ShareTask from "src/pages/General/ShareTask";
// import { OfficeAccessories } from "src/pages/General/OfficeAccessories";
// import ScannerUploading from "src/pages/General/ScannerUploading";

const NewOnlineUser = React.lazy(() =>
  import("src/pages/General/NewOnlineUser")
);
const OfflineUsers = React.lazy(() => import("src/pages/General/OfflineUsers"));
const BannerPage = React.lazy(() => import("src/pages/General/BannerPage"));
const Mobiles = React.lazy(() => import("src/pages/General/Mobiles"));
const Mystaff = React.lazy(() => import("src/pages/General/Mystaff"));
const ViewOnlineUser = React.lazy(() =>
  import("src/pages/General/ViewOnlineUser")
);
const MyPackages = React.lazy(() => import("src/pages/General/MyPackages"));
const BioDataUsers = React.lazy(() => import("src/pages/General/BioDataUsers"));
const BioDataEntryForms = React.lazy(() =>
  import("src/pages/General/BioDataEntryForms")
);
const HinduCasteEntries = React.lazy(() =>
  import("src/pages/General/HinduCasteEntries")
);
const HinduBioData = React.lazy(() => import("src/pages/General/HinduBioData"));
const ImagePreview = React.lazy(() => import("src/pages/General/ImagePreview"));
const HinduBioDataEntryForms = React.lazy(() =>
  import("src/pages/General/HinduBioDataEntryForms")
);
const OfflineUsersAdd = React.lazy(() =>
  import("src/pages/General/OfflineUsersAdd")
);
const Padadhikari = React.lazy(() => import("src/pages/General/Padadhikari"));
const Countries = React.lazy(() => import("src/pages/Forms/Countries"));
const States = React.lazy(() => import("src/pages/Forms/States"));
const Cities = React.lazy(() => import("src/pages/Forms/Cities"));
const Tehsils = React.lazy(() => import("src/pages/Forms/Tehsils"));
const Educations = React.lazy(() => import("src/pages/Forms/Educations"));
const Subeducations = React.lazy(() => import("src/pages/Forms/Subeducations"));
const Subsects = React.lazy(() => import("src/pages/Forms/Subsect"));
const Sects = React.lazy(() => import("src/pages/Forms/Sects"));
const Gotras = React.lazy(() => import("src/pages/Forms/Gotras"));
const Hobbies = React.lazy(() => import("src/pages/Forms/Hobbies"));
const MotherTongues = React.lazy(() => import("src/pages/Forms/Mothertongues"));
const Occupations = React.lazy(() => import("src/pages/Forms/Occupations"));
const Suboccupations = React.lazy(() =>
  import("src/pages/Forms/Suboccupations")
);
const Specialcollegespreferences = React.lazy(() =>
  import("src/pages/Forms/Specialcollegespreferences")
);
const Raashi = React.lazy(() => import("src/pages/Forms/Raashi"));
const Starnakshatra = React.lazy(() => import("src/pages/Forms/Starnakshatra"));
const Forms = React.lazy(() => import("src/pages/General/Forms"));
const RoleManagement = React.lazy(() =>
  import("src/pages/General/RoleManagement")
);
const StaffReport = React.lazy(() => import("src/pages/General/StaffReport"));
const BioDataUploading = React.lazy(() =>
  import("src/pages/General/BiodataUploading")
);
const HinduBioDataUploading = React.lazy(() =>
  import("src/pages/General/HinduBiodataUploading")
);
const BiodataDawnloading = React.lazy(() =>
  import("src/pages/General/BiodataDawnloading")
);
const BiodataDawnloadingUpdate = React.lazy(() =>
  import("src/pages/General/BiodataDawnloadingUpdate")
);
const WhatsappResponse = React.lazy(() =>
  import("src/pages/General/WhatsappResponse")
);
const HinduWhatsappResponse = React.lazy(() =>
  import("src/pages/General/HinduWhatsappResponse")
);
const WhatsappResponseAdd = React.lazy(() =>
  import("src/pages/General/WhatsappResponseAdd")
);
const HinduWhatsappResponseAdd = React.lazy(() =>
  import("src/pages/General/HinduWhatsappResponseAdd")
);
const Message = React.lazy(() => import("src/pages/General/Message"));
const Notes = React.lazy(() => import("src/pages/General/Notes"));
const FieldWork = React.lazy(() => import("src/pages/General/FieldWork"));
const FieldWorkAdd = React.lazy(() => import("src/pages/General/FieldWorkAdd"));
const HinduBiodataDawnloading = React.lazy(() =>
  import("src/pages/General/HinduBiodataDawnloading")
);
const HinduBiodataDawnloadingUpdate = React.lazy(() =>
  import("src/pages/General/HinduBiodataDawnloadingUpdate")
);
const Hinduforms = React.lazy(() => import("src/pages/General/HinduForms"));
const HinduCountries = React.lazy(() =>
  import("src/pages/Hinduforms/Countries")
);
const HinduStates = React.lazy(() => import("src/pages/Hinduforms/States"));
const HinduCities = React.lazy(() => import("src/pages/Hinduforms/Cities"));
const HinduTehsils = React.lazy(() => import("src/pages/Hinduforms/Tehsils"));
const HinduEducations = React.lazy(() =>
  import("src/pages/Hinduforms/Educations")
);
const HinduSubeducations = React.lazy(() =>
  import("src/pages/Hinduforms/Subeducations")
);
const HinduSubsects = React.lazy(() => import("src/pages/Hinduforms/Subsects"));
const HinduSects = React.lazy(() => import("src/pages/Hinduforms/Sects"));
const HinduGotras = React.lazy(() => import("src/pages/Hinduforms/Gotras"));
const HinduOccupations = React.lazy(() =>
  import("src/pages/Hinduforms/Occupations")
);
const HinduSuboccupations = React.lazy(() =>
  import("src/pages/Hinduforms/Suboccupations")
);
const GlobalSearch = React.lazy(() => import("src/pages/General/GlobalSearch"));
const ExcelHistory = React.lazy(() => import("src/pages/General/ExcelHistory"));
const HinduBioDataUpdate = React.lazy(() =>
  import("src/pages/General/HinduBioDataUpdate")
);
const BioDataUpdate = React.lazy(() =>
  import("src/pages/General/BioDataUpdate")
);
const PushNotification = React.lazy(() =>
  import("src/pages/General/PushNotification")
);
const PushNotificationAdd = React.lazy(() =>
  import("src/pages/General/PushNotificationAdd")
);
const HinduIncoming = React.lazy(() =>
  import("src/pages/General/HinduIncoming")
);
const HinduIncomingAdd = React.lazy(() =>
  import("src/pages/General/hinduIncomingAdd")
);
const TestUserHistory = React.lazy(() =>
  import("src/pages/General/TestUserHistory")
);
const UserRequest = React.lazy(() => import("src/pages/General/UserRequest"));
const ContactUs = React.lazy(() => import("src/pages/General/ContactUs"));
const PushNotificationHistory = React.lazy(() =>
  import("src/pages/General/PushNotificationHistory")
);
const ResponseData = React.lazy(() => import("src/pages/General/ResponseData"));
const WhatsappGroupServices = React.lazy(() =>
  import("src/pages/General/WhatsappGroupServices")
);
const PaidPromotion = React.lazy(() =>
  import("src/pages/General/PaidPromotion")
);
const SocialMedia = React.lazy(() => import("src/pages/General/SocialMedia"));
const ExcelImport = React.lazy(() =>
  import("src/sections/@dashboard/user/list/ExcelImport")
);
const PendingTask = React.lazy(() => import("src/pages/General/PendingTask"));
const IncomingUser = React.lazy(() => import("src/pages/General/IncomingUser"));
const IncomingUserAdd = React.lazy(() =>
  import("src/pages/General/IncomingUserAdd")
);
const MessageSection = React.lazy(() =>
  import("src/pages/General/MessageSection")
);
const GroupLinks = React.lazy(() => import("src/pages/General/GroupLinks"));
const HinduOnlineUser = React.lazy(() =>
  import("src/pages/General/HinduOnlineUser")
);
const Settings = React.lazy(() => import("src/pages/General/Settings"));
const MessageView = React.lazy(() => import("src/pages/General/MessageView"));
const FieldApprovel = React.lazy(() =>
  import("src/pages/General/FieldApprovel")
);
const HinduResponseData = React.lazy(() =>
  import("src/pages/General/HinduResponseData")
);
const HinduViewOnlineUser = React.lazy(() =>
  import("src/pages/General/HinduViewOnlineUser")
);
const HinduImagePreview = React.lazy(() =>
  import("src/pages/General/HinduImagePreview")
);
const HinduPushNotificationHistory = React.lazy(() =>
  import("src/pages/General/HinduPushNotificationHistory")
);
const ViewStaff = React.lazy(() => import("src/pages/General/ViewStaff"));
const BioDataScannerUpdate = React.lazy(() =>
  import("src/pages/General/BiodataScannerUpdate")
);
const OfficeAccessories = React.lazy(() =>
  import("src/pages/General/OfficeAccessories")
);
const ScannerUploading = React.lazy(() =>
  import("src/pages/General/ScannerUploading")
);
const HinduScannerUploading = React.lazy(() =>
  import("src/pages/General/HinduScannerUploading")
);
const BioDataUpdateScannerById = React.lazy(() =>
  import("src/pages/General/BioDataUpdateScannerById")
);
const ShareTask = React.lazy(() => import("src/pages/General/ShareTask"));

// ----------------------------------------------------------------------

export default function Router() {
  const user = JSON.parse(localStorage.getItem("sections"));
  const routeArr = [
    // Auth
    {
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <LoginPage /> },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            // { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralAppPage /> },
        // { path: 'onlineuser', element: <NewOnlineUser type="onlineUsers" /> },
        // {
        //   path: "onlineuser/edit/:id",
        //   element: <ViewOnlineUser type="onlineUsers" />,
        // },
        // { path: 'onlineuser/preview/', element: <ImagePreview /> },
        // { path: 'deleteduser', element: <NewOnlineUser type="deletedUsers" /> },
        // { path: 'deleteduser/edit/:id', element: <ViewOnlineUser type="deletedUsers" /> },
        // { path: 'bannerpage', element: <BannerPage /> },
        // { path: 'mypackages', element: <MyPackages /> },
        // { path: 'biodatausers', element: <BioDataUsers /> },
        // { path: 'biodataentryforms', element: <BioDataEntryForms /> },
        // { path: 'hinducasteentries', element: <HinduCasteEntries /> },
        // { path: 'mystaff', element: <Mystaff /> },
        // { path: 'mobiles', element: <Mobiles /> },
        // { path: 'hindubiodata', element: <HinduBioData /> },
        // { path: 'offlineUsers', element: <OfflineUsers /> },
        // { path: 'offlineUsersadd', element: <OfflineUsersAdd /> },
        {
          path: "hindubiodataentryforms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBioDataEntryForms />{" "}
            </React.Suspense>
          ),
        },
        // { path: 'padadhikari', element: <React.Suspense
        // fallback={
        //   <div
        //     style={{
        //       height: "100vh",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //     }}
        //   >
        //     <LoadingScreenSmall />
        //   </div>
        // }> <Padadhikari /> </React.Suspense>},
        {
          path: "forms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              <Forms />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodatauploading",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              <BioDataUploading />
            </React.Suspense>
          ),
        },
        {
          path: "hindubiodatauploading",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBioDataUploading />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodatadawnloading",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BiodataDawnloading />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodatadawnloadingupdate",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BiodataDawnloadingUpdate />
            </React.Suspense>
          ),
        },
        {
          path: "fieldapprovel",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldApprovel />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "officeaccessories",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <OfficeAccessories />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "grouplinks",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <GroupLinks />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "responsedata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ResponseData />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduresponsedata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduResponseData />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "responseimportdata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ExcelImport />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappresponse",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappResponse />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappgroupservices",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappGroupServices />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "paidpromotion",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PaidPromotion />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "socialmedia",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <SocialMedia />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduwhatsappresponse",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduWhatsappResponse />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduincoming",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduIncoming />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "incominguser",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <IncomingUser />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduincomingadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduIncomingAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappresponseadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappResponseAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduwhatsappresponseadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduWhatsappResponseAdd />
            </React.Suspense>
          ),
        },
        {
          path: "message",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Message />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "notes",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Notes />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "fieldwork",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldWork />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "fieldworkadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldWorkAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "countries",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Countries />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "states",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <States />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "cities",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Cities />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "tehsils",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Tehsils />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "educations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Educations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "subeducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Subeducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "subsects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Subsects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "sects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Sects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "gotras",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Gotras />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Hobbies",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Hobbies />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "MotherTongues",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MotherTongues />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Occupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Occupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Suboccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Suboccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Specialcollegespreferences",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Specialcollegespreferences />
            </React.Suspense>
          ),
        },
        {
          path: "Raashi",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Raashi />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Starnakshatra",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Starnakshatra />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindubiodatadawnloading",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBiodataDawnloading />
            </React.Suspense>
          ),
        },
        {
          path: "hindubiodatadawnloadingupdate",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBiodataDawnloadingUpdate />
            </React.Suspense>
          ),
        },
        {
          path: "hindubiodataupdate/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBioDataUpdate />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodataupdate/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BioDataUpdate />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodataupdatescanner",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BioDataScannerUpdate />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodataupdatescanner/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BioDataUpdateScannerById />
            </React.Suspense>
          ),
        },
        {
          path: "hinduforms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Hinduforms />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinducountries",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduCountries />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindustates",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduStates />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinducities",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduCities />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindutehsils",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduTehsils />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindueducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduEducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSubeducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSubeducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSubsects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSubsects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindugotras",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduGotras />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduoccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduOccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindusuboccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSuboccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "globalsearch",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <GlobalSearch />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "pushNotification",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PushNotification />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "excelhistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ExcelHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "officeaccessoriesHistory/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <OfficeAccessoriesHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "userRequest",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <UserRequest />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "languageViewSection/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MessageView />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "addMessageSection",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MessageSection />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "settings",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Settings />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "scannerUpload",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ScannerUploading />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "scannerUploadHindu",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduScannerUploading />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "pushnotificationhistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PushNotificationHistory />
            </React.Suspense>
          ),
        },
        {
          path: "download-history",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <DownlaodDataHistory />
            </React.Suspense>
          ),
        },
        {
          path: "analytics-history",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <AnalyticsHistory />
            </React.Suspense>
          ),
        },
        {
          path: "hinduPushNotificationHistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduPushNotificationHistory />
            </React.Suspense>
          ),
        },
      ],
    },

    // Main Routes
    {
      children: [
        { element: <Navigate to={"/auth/login"} replace />, index: true },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  if (user?.roles === "admin") {
    routeArr[1].children.push({
      path: "rolemanagement",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <RoleManagement />{" "}
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "bannerpage",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BannerPage />{" "}
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "testUserHistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <TestUserHistory />{" "}
        </React.Suspense>
      ),
    });
    // routeArr[1].children.push({ path: 'excelhistory', element: <ExcelHistory /> })
  }

  if (user?.assignRole?.role === "Admin") {
    routeArr[1].children.push({
      path: "bannerpage",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BannerPage />
        </React.Suspense>
      ),
    });
  }

  if (user?.assignRole?.role === "Admin" || user?.roles === "admin") {
    routeArr[1].children.push({
      path: "contactus",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ContactUs />
        </React.Suspense>
      ),
    });
  }

  if (user?.sections?.roles?.read) {
    routeArr[1].children.push({
      path: "rolemanagement",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <RoleManagement />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.staffReport?.read) {
    routeArr[1].children.push({
      path: "staffreport",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <StaffReport />
        </React.Suspense>
      ),
    });
  }

  if (user?.roles === "master") {
    routeArr[1].children.push({
      path: "excelhistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ExcelHistory />
        </React.Suspense>
      ),
    });
    // routeArr[1].children.push({ path: "testUserHistory", element: <TestUserHistory /> })
  }
  if (user?.roles === "master") {
    routeArr[1].children.push({
      path: "excelhistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ExcelHistory />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.onlineUsers?.read) {
    routeArr[1].children.push(
      {
        path: "onlineuser",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            <NewOnlineUser type="onlineUsers" />
          </React.Suspense>
        ),
      },
      {
        path: "onlineuser/edit/:id",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            {" "}
            <ViewOnlineUser type="onlineUsers" />
          </React.Suspense>
        ),
      },
      {
        path: "onlineuser/preview/",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            {" "}
            <ImagePreview />
          </React.Suspense>
        ),
      }
    );
  }
  if (user?.sections?.onlineUsers?.read) {
    routeArr[1].children.push({
      path: "hinduonlineuser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduOnlineUser type="hinduOnlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hinduonlineuser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduViewOnlineUser type="hinduOnlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hinduonlineuser/preview/",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduImagePreview />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.["Staff To Verify"]?.read || user?.roles === "admin") {
    routeArr[1].children.push({
      path: "onlineuser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewOnlineUser type="onlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "pendingTask",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <PendingTask />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "newGlobalSearch",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewGlobalSearch />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "shareTask",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ShareTask />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "onlineuser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewOnlineUser type="onlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "onlineuser/preview/",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ImagePreview />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.DeletedUsers?.read) {
    routeArr[1].children.push({
      path: "deleteduser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewOnlineUser type="deletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "deleteduser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewOnlineUser type="deletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hindudeleteduser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduOnlineUser type="hinduDeletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hindudeleteduser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduViewOnlineUser type="hinduDeletedUsers" />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.packages?.read)
    routeArr[1].children.push({
      path: "mypackages",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <MyPackages />
        </React.Suspense>
      ),
    });

  if (user?.sections?.bioDataUsers?.read)
    routeArr[1].children.push({
      path: "biodatausers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BioDataUsers />
        </React.Suspense>
      ),
    });
  if (user?.sections?.bioDataUsers?.create)
    routeArr[1].children.push({
      path: "biodataentryforms",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BioDataEntryForms />
        </React.Suspense>
      ),
    });

  if (user?.sections?.hinduBioData?.read)
    routeArr[1].children.push({
      path: "hindubiodata",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduBioData />
        </React.Suspense>
      ),
    });
  if (user?.sections?.hinduBioData?.create)
    routeArr[1].children.push({
      path: "hindubiodataentryforms",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduBioDataEntryForms />
        </React.Suspense>
      ),
    });

  if (user?.sections?.staff?.read) {
    routeArr[1].children.push({
      path: "mystaff",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Mystaff />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "mystaff/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewStaff type="staff" />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.mobiles?.read)
    routeArr[1].children.push({
      path: "mobiles",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Mobiles />
        </React.Suspense>
      ),
    });

  if (user?.sections?.offlineUsers?.read)
    routeArr[1].children.push({
      path: "offlineUsers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          <OfflineUsers />
        </React.Suspense>
      ),
    });
  if (user?.sections?.offlineUsers?.create)
    routeArr[1].children.push({
      path: "offlineUsersadd",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <OfflineUsersAdd />
        </React.Suspense>
      ),
    });

  if (user?.sections?.IncomingUsers?.read)
    routeArr[1].children.push({
      path: "incominguser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <IncomingUser />
        </React.Suspense>
      ),
    });
  if (user?.sections?.IncomingUsers?.create)
    routeArr[1].children.push({
      path: "incominguseradd",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <IncomingUserAdd />
        </React.Suspense>
      ),
    });

  if (user?.sections?.padadhikari?.read)
    routeArr[1].children.push({
      path: "padadhikari",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Padadhikari />
        </React.Suspense>
      ),
    });

  return useRoutes(routeArr);
}
